<template>
	<div class="container-fluid px-0 h-100">
		<CCard class="custom__card">
			<CCardBody class="custom__card__body">
				<div class="form__header">
					<h3>{{ $t("ReceivingForm.Header") }}</h3>
				</div>
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(submitForm)">
						<div class="custom">
							<div class="custom__input">
								<!-- Technician Email  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="email"
										:rules="`requiredExport|isEmail`"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="email" class="col-md-2 col-12 col-form-label">{{
												$t("ReceivingForm.Email")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<input
													id="email"
													v-model.trim="formRequest.email"
													type="text"
													class="form-control"
													:placeholder="$t('ReceivingForm.Email')"
													autocomplete="email"
													:maxlength="FORM_REQUEST.EMAIL"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="title"
										:rules="`requiredExport`"
									>
										<div class="form-group required row">
											<label for="title" class="col-md-2 col-12 col-form-label">{{
												$t("ReceivingForm.Title")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<input
													id="title"
													v-model.trim="formRequest.title"
													type="text"
													class="form-control"
													:placeholder="$t('ReceivingForm.Title')"
													autocomplete="title"
													:maxlength="FORM_REQUEST.TITLE"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
								<div class="form-group position-relative">
									<ValidationProvider v-slot="{ classes, errors }" name="type">
										<div class="form-group row">
											<label for="type" class="col-md-2 col-12 col-form-label">{{
												$t("ReceivingForm.Type")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<multiselect
													id="type"
													v-model="formRequest.types"
													multiple
													taggable
													label="name"
													track-by="value"
													class="custom_multiselect"
													:options="listType"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="true"
													:searchable="true"
													:placeholder="$t('ReceivingForm.Type')"
													@select="selectType"
													@remove="clearType"
												>
													<template #noOptions>
														{{ $t("DropDown.NoOptions") }}
													</template>
													<template #noResult>
														{{ $t("DropDown.NoResult") }}
													</template>
												</multiselect>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="description"
										:rules="`requiredExport`"
									>
										<div class="form-group required row">
											<label for="description" class="col-md-2 col-12 col-form-label">{{
												$t("ReceivingForm.Description")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<textarea
													id="description"
													v-model.trim="formRequest.description"
													rows="6"
													type="text"
													:placeholder="$t('ReceivingForm.Description')"
													class="form-control remove-resize"
													autocomplete="description"
													:maxlength="FORM_REQUEST.DESCRIPTION"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
								<UploadFiles
									name="request form"
									:is-upload-success="isPressConfirm"
									:show-delete="true"
									:files="uploadFiles"
									:show-add="true"
									:is-update="true"
									:is-required="false"
									@handleUploadFiles="handleUploadFiles"
									@handleDeleteFile="handleDeleteFile"
									@total-file="getTotalFiles"
								/>
								<div class="form-group position-relative">
									<div class="form-group row">
										<label class="col-md-2 col-12 col-form-label"></label>
										<div :class="['col-md-10 col-12']">{{ $t("ReceivingForm.TitleLink") }}</div>
									</div>
								</div>
								<!-- link dropbox -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="dropbox"
										name="linkDropbox"
										mode="lazy"
										:rules="`isUrl`"
									>
										<div class="form-group row">
											<label for="linkDropbox" class="col-md-2 col-12 col-form-label">{{
												$t("ReceivingForm.linkDropbox")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<div class="position-relative">
													<input
														id="linkDropbox"
														v-model.trim="formRequest.dropbox"
														type="text"
														class="form-control pr-5"
														:placeholder="$t('ReceivingForm.linkDropbox')"
														autocomplete="linkDropbox"
													/>
													<button
														class="px-2 btn btn-outline-custom icon"
														type="button"
														:disabled="!formRequest.dropbox"
														@click="addLink('dropbox')"
													>
														<font-awesome-icon :icon="['fas', 'plus']" :title="$t('Action.Add')" />
													</button>
												</div>
												<div v-if="errors.length" class="error-mess">
													{{ errors[0] }}
												</div>
												<div
													v-if="
														formRequest.dropboxSharedFolderLinks &&
														formRequest.dropboxSharedFolderLinks.length
													"
													class="py-3 dropbox-list"
												>
													<div
														v-for="(dropboxLink, index1) in formRequest.dropboxSharedFolderLinks"
														:key="index1"
														class="d-flex justify-content-between align-items-center dropbox-item mb-1"
													>
														<a :href="dropboxLink" target="_blank" class="dropbox-url">{{
															dropboxLink
														}}</a>
														<button
															class="px-2 btn btn-outline-danger btn-delete"
															type="button"
															@click="removeLink('dropbox', index1)"
														>
															<font-awesome-icon
																:icon="['fas', 'trash-alt']"
																:title="$t('Action.Delete')"
															/>
														</button>
													</div>
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- link drive -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="drive"
										name="linkDrive"
										mode="lazy"
										:rules="`isUrl`"
									>
										<div class="form-group row">
											<label for="linkDrive" class="col-md-2 col-12 col-form-label">{{
												$t("ReceivingForm.linkDrive")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<div class="position-relative">
													<input
														id="linkDrive"
														v-model.trim="formRequest.drive"
														type="text"
														class="form-control pr-5"
														:placeholder="$t('ReceivingForm.linkDrive')"
														autocomplete="linkDrive"
													/>
													<button
														class="px-2 btn btn-outline-custom icon"
														type="button"
														:disabled="!formRequest.drive"
														@click="addLink('drive')"
													>
														<font-awesome-icon :icon="['fas', 'plus']" :title="$t('Action.Add')" />
													</button>
												</div>
												<div v-if="errors.length" class="error-mess">
													{{ errors[0] }}
												</div>
												<div
													v-if="
														formRequest.driveSharedFolderLinks &&
														formRequest.driveSharedFolderLinks.length
													"
													class="py-3 dropbox-list"
												>
													<div
														v-for="(driveLink, index2) in formRequest.driveSharedFolderLinks"
														:key="index2"
														class="d-flex justify-content-between align-items-center dropbox-item mb-1"
													>
														<a :href="driveLink" target="_blank" class="dropbox-url">{{
															driveLink
														}}</a>
														<button
															class="px-2 btn btn-outline-danger btn-delete"
															type="button"
															@click="removeLink('drive', index2)"
														>
															<font-awesome-icon
																:icon="['fas', 'trash-alt']"
																:title="$t('Action.Delete')"
															/>
														</button>
													</div>
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>

						<CRow class="text-right">
							<CCol>
								<button :disabled="checkUploadFiles" class="btn btn-outline-custom" type="submit"
									>Submit</button
								>
							</CCol>
						</CRow>
					</form>
				</ValidationObserver>
			</CCardBody>
		</CCard>
		<CustomModal
			v-if="uploadSuccess"
			:title="!isSuccess ? $t('messages.upload.header') : ''"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="confirm"
			@cancel-modal="confirm"
		>
			<template #content>
				{{ !isSuccess ? $t("messages.upload.body") : $t("messages.formRequest") }}
			</template>
		</CustomModal>
		<CustomModal
			v-if="isDuplicateFile"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="confirmDuplicate"
			@cancel-modal="confirmDuplicate"
		>
			<template #content>
				{{ messageDuplicate }}
			</template>
		</CustomModal>
		<SvgSprite />
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { FORM_REQUEST } from "@/shared/plugins/constants"
import { join, uniqBy } from "lodash-es"
export default {
	name: "ReceivingForm",
	components: {
		SvgSprite: () => import("../containers/SvgSprite"),
		UploadFiles: () => import("../components/UploadFiles"),
	},
	data() {
		return {
			uploadFiles: [],
			isPressConfirm: false,
			uploadSuccess: false,
			isDuplicateFile: false,
			FORM_REQUEST,
			formRequest: {
				email: null,
				title: null,
				types: [],
				description: null,
				dropbox: null,
				drive: null,
				dropboxSharedFolderLinks: [],
				driveSharedFolderLinks: [],
			},
			listType: [],
			messageDuplicate: null,
			filesNotDuplicate: null,
			totalFiles: null,
			isUploadSuccess: null,
		}
	},
	computed: {
		...mapState({
			isSuccess: state => state.isSuccess,
			recentFilters: state => state.typeOrder.recentFilters,
			typesOrder: state => state.typeOrder.typesOrder,
		}),
		checkRequiredFile() {
			if (this.uploadFiles.length === 0) {
				return true
			}
			return false
		},
		checkUploadFiles() {
			if (this.uploadFiles.length && !this.isUploadSuccess) {
				return true
			}
			return false
		},
	},
	created() {
		this.getTypes()
	},
	methods: {
		...mapActions(["uploadFile", "submitFormRequest", "typeOrder/GET_TYPES_ORDER"]),
		...mapMutations(["removeToken"]),
		async getTypes() {
			await this["typeOrder/GET_TYPES_ORDER"](this.recentFilters)
			if (this.typesOrder) {
				this.listType = [...this.typesOrder].map(el => {
					return {
						name: el.type,
						value: el.id,
					}
				})
			}
		},

		confirm() {
			if (this.isSuccess) {
				this.formRequest = {
					email: null,
					title: null,
					types: [],
					description: null,
					dropbox: null,
					drive: null,
					dropboxSharedFolderLinks: [],
					driveSharedFolderLinks: [],
				}
				this.$nextTick(() => {
					this.$refs.form.reset()
				})
				this.uploadFiles = []
				this.isPressConfirm = true
			} else {
				this.$refs.fileInput.click()
			}
			this.uploadSuccess = false
			this.$store.commit("set", ["modalShow", false])
		},
		selectType() {},
		clearType() {},
		getTotalFiles(total) {
			this.totalFiles = total
		},
		async handleUploadFiles(files) {
			this.isUploadSuccess = false
			this.uploadFiles.push(...files)
			const newFiles = this.uploadFiles
			const result = await this.getFileNameDuplicate(newFiles)
			if (result.length) {
				const fileName = result.map(el => el.name)
				this.messageDuplicate = `${this.$t("messages.duplicate.left")} ${join(
					fileName,
					", ",
				)} ${this.$t("messages.duplicate.right")}`
				this.isDuplicateFile = true
				this.$store.commit("set", ["modalShow", true])
			}
			this.uploadFiles = newFiles
			if (this.uploadFiles.length === this.totalFiles) this.isUploadSuccess = true
		},
		handleDeleteFile(index) {
			this.uploadFiles.splice(index, 1)
		},
		async submitForm() {
			const files = this.uploadFiles.map(el => el.file)
			files.length ? this.showProgressBar() : this.showLoading()
			if (files.length) {
				const res = await this.uploadFile(files)
				res.ok && (await this.createForm(res.tokens))
			} else {
				await this.createForm()
			}
			files.length ? this.hideProgressBar() : this.hideLoading()
			this.uploadSuccess = true
			this.$store.commit("set", ["isSuccess", true])
			this.$store.commit("set", ["modalShow", true])
		},
		async createForm(tokens) {
			const params = {
				email: this.formRequest.email,
				title: this.formRequest.title,
				description: this.formRequest.description,
				orderTypes: this.convertObjectToArray(this.formRequest.types),
			}

			if (tokens) {
				params["tokens"] = tokens
			}

			if (this.formRequest.dropboxSharedFolderLinks.length) {
				params["dropboxSharedFolderLinks"] = this.formRequest.dropboxSharedFolderLinks
			}
			if (this.formRequest.driveSharedFolderLinks.length) {
				params["driveSharedFolderLinks"] = this.formRequest.driveSharedFolderLinks
			}

			await this.submitFormRequest(params)
		},
		convertObjectToArray(data) {
			let arr = []
			data.forEach(el => {
				arr.push(el.value)
			})
			return arr
		},
		confirmDuplicate() {
			this.uploadFiles = uniqBy(this.uploadFiles, "name")
			this.isDuplicateFile = false
			this.$store.commit("set", ["modalShow", false])
		},
		async addLink(type) {
			const validate = await this.$refs[type].validate()
			const value = this.$refs[type].value

			if (!validate.valid || !value) {
				return
			}

			if (type === "dropbox") {
				this.formRequest.dropboxSharedFolderLinks.unshift(this.formRequest.dropbox)
				this.formRequest.dropbox = null
			} else {
				this.formRequest.driveSharedFolderLinks.unshift(this.formRequest.drive)
				this.formRequest.drive = null
			}
		},
		removeLink(type, index) {
			if (type === "dropbox") {
				this.formRequest.dropboxSharedFolderLinks.splice(index, 1)
			} else {
				this.formRequest.driveSharedFolderLinks.splice(index, 1)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.custom__card {
	min-height: 100%;
	margin-bottom: 0;
}
.custom__card__body {
	padding: 35px 55px 35px 35px;
}
.form__header {
	padding: 0 18px;
	h3 {
		margin-bottom: 26px;
	}
}
.custom__input {
	width: 100%;
	padding-left: 20px;
}
.remove-resize {
	resize: none;
}
.btn__submit {
	width: 125px;
	height: 44px;
	background: $green-1;
	border-radius: 100px;
	font-weight: 600;
	font-size: 13px;
	line-height: 20px;

	text-align: center;

	color: $white;
	border: none;

	&:focus {
		outline: none;
	}
}

.icon {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	z-index: 10;
	min-width: 40px;
	height: 35px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	svg {
		font-size: 18px;
	}
}

.dropbox-url {
	width: calc(100% - 60px);
}

.btn-delete {
	min-width: 40px;
	height: 35px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	svg {
		font-size: 18px;
	}
}

.dropbox {
	&-item {
		padding-left: 12px;
		border: 1px solid $border-input;
		border-radius: 0.25rem;
		border-right: unset;
	}
}

.disabled-link {
	background: $grey-5;
	cursor: not-allowed;
}
</style>
