var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid px-0 h-100"},[_c('CCard',{staticClass:"custom__card"},[_c('CCardBody',{staticClass:"custom__card__body"},[_c('div',{staticClass:"form__header"},[_c('h3',[_vm._v(_vm._s(_vm.$t("ReceivingForm.Header")))])]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"custom"},[_c('div',{staticClass:"custom__input"},[_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"requiredExport|isEmail","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group required row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t("ReceivingForm.Email")))]),_c('div',{class:['col-md-10 col-12', classes]},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formRequest.email),expression:"formRequest.email",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"email","type":"text","placeholder":_vm.$t('ReceivingForm.Email'),"autocomplete":"email","maxlength":_vm.FORM_REQUEST.EMAIL},domProps:{"value":(_vm.formRequest.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formRequest, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{attrs:{"name":"title","rules":"requiredExport"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group required row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"title"}},[_vm._v(_vm._s(_vm.$t("ReceivingForm.Title")))]),_c('div',{class:['col-12 col-md-10', classes]},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formRequest.title),expression:"formRequest.title",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"title","type":"text","placeholder":_vm.$t('ReceivingForm.Title'),"autocomplete":"title","maxlength":_vm.FORM_REQUEST.TITLE},domProps:{"value":(_vm.formRequest.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formRequest, "title", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{attrs:{"name":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"type"}},[_vm._v(_vm._s(_vm.$t("ReceivingForm.Type")))]),_c('div',{class:['col-12 col-md-10', classes]},[_c('multiselect',{staticClass:"custom_multiselect",attrs:{"id":"type","multiple":"","taggable":"","label":"name","track-by":"value","options":_vm.listType,"close-on-select":true,"show-labels":false,"allow-empty":true,"searchable":true,"placeholder":_vm.$t('ReceivingForm.Type')},on:{"select":_vm.selectType,"remove":_vm.clearType},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("DropDown.NoOptions"))+" ")]},proxy:true},{key:"noResult",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("DropDown.NoResult"))+" ")]},proxy:true}],null,true),model:{value:(_vm.formRequest.types),callback:function ($$v) {_vm.$set(_vm.formRequest, "types", $$v)},expression:"formRequest.types"}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)])]}}],null,true)})],1),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{attrs:{"name":"description","rules":"requiredExport"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group required row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"description"}},[_vm._v(_vm._s(_vm.$t("ReceivingForm.Description")))]),_c('div',{class:['col-12 col-md-10', classes]},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formRequest.description),expression:"formRequest.description",modifiers:{"trim":true}}],staticClass:"form-control remove-resize",attrs:{"id":"description","rows":"6","type":"text","placeholder":_vm.$t('ReceivingForm.Description'),"autocomplete":"description","maxlength":_vm.FORM_REQUEST.DESCRIPTION},domProps:{"value":(_vm.formRequest.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formRequest, "description", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1),_c('UploadFiles',{attrs:{"name":"request form","is-upload-success":_vm.isPressConfirm,"show-delete":true,"files":_vm.uploadFiles,"show-add":true,"is-update":true,"is-required":false},on:{"handleUploadFiles":_vm.handleUploadFiles,"handleDeleteFile":_vm.handleDeleteFile,"total-file":_vm.getTotalFiles}}),_c('div',{staticClass:"form-group position-relative"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label"}),_c('div',{class:['col-md-10 col-12']},[_vm._v(_vm._s(_vm.$t("ReceivingForm.TitleLink")))])])]),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{ref:"dropbox",attrs:{"name":"linkDropbox","mode":"lazy","rules":"isUrl"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"linkDropbox"}},[_vm._v(_vm._s(_vm.$t("ReceivingForm.linkDropbox")))]),_c('div',{class:['col-md-10 col-12', classes]},[_c('div',{staticClass:"position-relative"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formRequest.dropbox),expression:"formRequest.dropbox",modifiers:{"trim":true}}],staticClass:"form-control pr-5",attrs:{"id":"linkDropbox","type":"text","placeholder":_vm.$t('ReceivingForm.linkDropbox'),"autocomplete":"linkDropbox"},domProps:{"value":(_vm.formRequest.dropbox)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formRequest, "dropbox", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('button',{staticClass:"px-2 btn btn-outline-custom icon",attrs:{"type":"button","disabled":!_vm.formRequest.dropbox},on:{"click":function($event){return _vm.addLink('dropbox')}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus'],"title":_vm.$t('Action.Add')}})],1)]),(errors.length)?_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),(
													_vm.formRequest.dropboxSharedFolderLinks &&
													_vm.formRequest.dropboxSharedFolderLinks.length
												)?_c('div',{staticClass:"py-3 dropbox-list"},_vm._l((_vm.formRequest.dropboxSharedFolderLinks),function(dropboxLink,index1){return _c('div',{key:index1,staticClass:"d-flex justify-content-between align-items-center dropbox-item mb-1"},[_c('a',{staticClass:"dropbox-url",attrs:{"href":dropboxLink,"target":"_blank"}},[_vm._v(_vm._s(dropboxLink))]),_c('button',{staticClass:"px-2 btn btn-outline-danger btn-delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeLink('dropbox', index1)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash-alt'],"title":_vm.$t('Action.Delete')}})],1)])}),0):_vm._e()])])]}}],null,true)})],1),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{ref:"drive",attrs:{"name":"linkDrive","mode":"lazy","rules":"isUrl"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
												var classes = ref.classes;
												var errors = ref.errors;
return [_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"linkDrive"}},[_vm._v(_vm._s(_vm.$t("ReceivingForm.linkDrive")))]),_c('div',{class:['col-md-10 col-12', classes]},[_c('div',{staticClass:"position-relative"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formRequest.drive),expression:"formRequest.drive",modifiers:{"trim":true}}],staticClass:"form-control pr-5",attrs:{"id":"linkDrive","type":"text","placeholder":_vm.$t('ReceivingForm.linkDrive'),"autocomplete":"linkDrive"},domProps:{"value":(_vm.formRequest.drive)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formRequest, "drive", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('button',{staticClass:"px-2 btn btn-outline-custom icon",attrs:{"type":"button","disabled":!_vm.formRequest.drive},on:{"click":function($event){return _vm.addLink('drive')}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus'],"title":_vm.$t('Action.Add')}})],1)]),(errors.length)?_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),(
													_vm.formRequest.driveSharedFolderLinks &&
													_vm.formRequest.driveSharedFolderLinks.length
												)?_c('div',{staticClass:"py-3 dropbox-list"},_vm._l((_vm.formRequest.driveSharedFolderLinks),function(driveLink,index2){return _c('div',{key:index2,staticClass:"d-flex justify-content-between align-items-center dropbox-item mb-1"},[_c('a',{staticClass:"dropbox-url",attrs:{"href":driveLink,"target":"_blank"}},[_vm._v(_vm._s(driveLink))]),_c('button',{staticClass:"px-2 btn btn-outline-danger btn-delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeLink('drive', index2)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash-alt'],"title":_vm.$t('Action.Delete')}})],1)])}),0):_vm._e()])])]}}],null,true)})],1)],1)]),_c('CRow',{staticClass:"text-right"},[_c('CCol',[_c('button',{staticClass:"btn btn-outline-custom",attrs:{"disabled":_vm.checkUploadFiles,"type":"submit"}},[_vm._v("Submit")])])],1)],1)]}}])})],1)],1),(_vm.uploadSuccess)?_c('CustomModal',{attrs:{"title":!_vm.isSuccess ? _vm.$t('messages.upload.header') : '',"submit-text":_vm.$t('Button.OK'),"is-show-cancel":false},on:{"submit-modal":_vm.confirm,"cancel-modal":_vm.confirm},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(!_vm.isSuccess ? _vm.$t("messages.upload.body") : _vm.$t("messages.formRequest"))+" ")]},proxy:true}],null,false,3034517214)}):_vm._e(),(_vm.isDuplicateFile)?_c('CustomModal',{attrs:{"submit-text":_vm.$t('Button.OK'),"is-show-cancel":false},on:{"submit-modal":_vm.confirmDuplicate,"cancel-modal":_vm.confirmDuplicate},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.messageDuplicate)+" ")]},proxy:true}],null,false,2156202373)}):_vm._e(),_c('SvgSprite')],1)}
var staticRenderFns = []

export { render, staticRenderFns }